import axios from 'axios';
import Swiper, { Navigation, Pagination } from 'swiper/swiper-bundle.js';
const moment = require('moment');
Swiper.use([Navigation, Pagination]);

$('.nav__sub').css({
	display: 'flex',
})

const topswiper = new Swiper('.toppage__mainimage__area .swiper', {
	speed: 1000,
	spaceBetween: 0,
	centeredSlides: true,
	effect: 'fade',
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
	},
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

const topbtnswiper = new Swiper('.toppage__btns .swiper', {
	speed: 1000,
	loop: true,
	slidesPerView: 'auto',
	centeredSlides: true,
	spaceBetween: 10,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
	}
});

$(document).on('click', '.toppage__switch a', function () {
	$(this).addClass('current');
	const $id = $(this).attr('href');
	$($id).addClass('current');
	$('.toppage__container').not($($id)).removeClass('current');
	$('.toppage__switch a').not($(this)).removeClass('current');
	return false;
})

var app = new Vue({
	el: '#toppage__search',
	data() {
		return {
			lists: [],
			isVisibleSearch: true, // 探す選択
			isVisibleMyMap: false, // マイマップ
			isVisibleResult: false, // 検索結果
			isVisibleResultArea: false, // 検索結果（エリア）
			isVisibleResultMedicalSubject: false, // 検索結果（診療科目）
			isVisibleList: false, // 施設リスト
			items: '',
			areas: '',
			medicalSubjects: '',
		}
	},
	created() { },
	mounted() {
		axios
			// .get('https://www.tokiwa.or.jp/renewal/search/list.php')
			.get('https://www.tokiwa.or.jp/search/list.php')
			.then((response) => {
				this.items = response.data

				axios
					// .get('https://www.tokiwa.or.jp/renewal/search/category1.php')
					.get('https://www.tokiwa.or.jp/search/category1.php')
					.then((response) => {
						this.areas = response.data

						axios
							// .get('https://www.tokiwa.or.jp/renewal/search/category2.php')
							.get('https://www.tokiwa.or.jp/search/category2.php')
							.then((response) => {
								this.medicalSubjects = response.data

								this.$nextTick(() => {
									const es = document.querySelectorAll(
										'#result > div > ul > li'
									)

									this.isVisibleList = false
									for (let i = 0; i < es.length; i++) {
										const id = es[i].getAttribute('data-id')
										this.lists.push(id)
									}

									// 「〇すべて」にチェック
									for (let i = 0; i < this.lists.length; i++) {
										const id = this.lists[i]
										const e = document.querySelector('#' + id + ' .all')

										let es = document.querySelectorAll(
											'#' + id + ' .list ol li'
										)
										e.classList.add('check')
										for (let j = 0; j < es.length; j++) {
											es[j].style.display = 'block'
										}
										es = document.querySelectorAll(
											'#' + id + ' ol li:not(.' + id + ')'
										)
										for (let j = 0; j < es.length; j++) {
											es[j].style.display = 'none'
										}
									}

									// スムーズスクロール
									const smoothScroll = (e) => {
										const target = document.querySelector(
											e.currentTarget.getAttribute('href')
										)
										const position =
											target.getBoundingClientRect().top + window.scrollY - 30
										e.preventDefault()
										window.scrollTo({
											top: position,
											behavior: 'smooth',
										})
									}

									const buttons = document.querySelectorAll('.scroll__button')

									buttons.forEach((button) => {
										button.addEventListener('click', smoothScroll)
									})

									this.isVisibleResult = true
									this.isVisibleResultArea = true
									this.isVisibleList = true
								})
							})
					})
			})
	},
	methods: {
		searchArea(e) {
			const id = e.currentTarget.getAttribute('data-id')
			this.isVisibleResultArea = true
			this.isVisibleMyMap = false
			this.isVisibleResultMedicalSubject = false
			if (id) {
				window.location.hash = ''
				window.location.hash = id
			} else if (this.is_mobile()) {
				window.location.hash = ''
				window.location.hash = 'result'
			}
		},
		searchMedicalSubject(e) {
			const id = e.currentTarget.getAttribute('data-id')
			this.isVisibleResultArea = false
			this.isVisibleMyMap = false
			this.isVisibleResultMedicalSubject = true
			if (id) {
				window.location.hash = ''
				window.location.hash = id
			} else if (this.is_mobile()) {
				window.location.hash = ''
				window.location.hash = 'result'
			}
		},
		// エリアを選び直す
		backArea() {
			window.location.hash = ''
			window.location.hash = 'search-area'
		},
		// 科目を選び直す
		backMedicalSubject() {
			window.location.hash = ''
			window.location.hash = 'search-medical-subject'
		},
		checkA(e) {
			let es = []
			const id = e.currentTarget.parentNode.parentNode.parentNode.getAttribute(
				'data-id'
			)
			this.isVisibleList = false
			es = document.querySelectorAll('#' + id + ' .select')
			for (let i = 0; i < es.length; i++) {
				es[i].classList.remove('check')
			}
			if (!e.currentTarget.classList.contains('check')) {
				e.currentTarget.classList.add('check')
				es = document.querySelectorAll('#' + id + ' ol li')
				for (let i = 0; i < es.length; i++) {
					es[i].style.display = 'block'
				}
				es = document.querySelectorAll('#' + id + ' ol li:not(.' + id + ')')
				for (let i = 0; i < es.length; i++) {
					es[i].style.display = 'none'
				}
			} else {
				e.currentTarget.classList.remove('check')
				es = document.querySelectorAll('#' + id + ' ol li')
				for (let i = 0; i < es.length; i++) {
					es[i].style.display = 'none'
				}
			}
			for (let i = 0; i < es.length; i++) {
				es[i].classList.remove('img-wrap')
				es[i].classList.add('img-wrap')
			}
			this.isVisibleList = true
		},
		checkB(e) {
			let es = []
			const id = e.currentTarget.parentNode.parentNode.getAttribute('data-id')
			let not = ''
			this.isVisibleList = false
			document.querySelector('#' + id + ' .all').classList.remove('check')
			if (!e.currentTarget.classList.contains('check')) {
				e.currentTarget.classList.add('check')
			} else {
				e.currentTarget.classList.remove('check')
			}
			es = document.querySelectorAll('#' + id + ' .select.check')
			for (let i = 0; i < es.length; i++) {
				not += ':not(.' + es[i].getAttribute('data-id') + ')'
			}
			es = document.querySelectorAll('#' + id + ' ol li')
			for (let i = 0; i < es.length; i++) {
				es[i].style.display = 'block'
			}
			es = document.querySelectorAll('#' + id + ' ol li' + not)
			for (let i = 0; i < es.length; i++) {
				es[i].style.display = 'none'
			}
			for (let i = 0; i < es.length; i++) {
				es[i].classList.remove('img-wrap')
				es[i].classList.add('img-wrap')
			}
			this.isVisibleList = true
		},
		searchMyMap() {
			this.isVisibleResultArea = false
			this.isVisibleMyMap = true
			this.isVisibleResultMedicalSubject = false
			if (this.is_mobile()) {
				window.location.hash = ''
				window.location.hash = 'result'
			}
		},
		is_mobile() {
			if (
				navigator.userAgent.indexOf('iPhone') > 0 ||
				(navigator.userAgent.indexOf('Android') > 0 &&
					navigator.userAgent.indexOf('Mobile') > 0) ||
				navigator.userAgent.indexOf('iPad') > 0 ||
				navigator.userAgent.indexOf('Android') > 0
			) {
				return true
			}
		},
	}
});
