import ScrollHint from 'scroll-hint';
import axios from 'axios';
$(function () {

	const tableScroll = new ScrollHint('.js-scrollable', {
		i18n: {
			scrollable: 'スクロールできます',
		},
	})
	if (window.matchMedia("screen and (max-width: 768px)").matches) {
		$(document).on('click', '.sp__nav .bar', function () {
			$(this).toggleClass('is-active');
			$(".nav").toggleClass('is-active');
		})
		$('.nav').css({
			top: $('.header').outerHeight(),
			'max-height': $(window).height() - $('.header').outerHeight(),
		})
	} else {
		setTimeout(() => {
			$('.nav__sub').css({
				'margin-left':
					($('.nav__sub').outerWidth() / 2) * -1 + $('.nav1').width() / 2,
			})
			$('.nav__search__box').css({
				'margin-left':
					($('.nav__search__box').outerWidth() / 2) * -1 +
					$('.nav__search a').width() / 2,
			})
		}, 100)
		$(document).on('click', '.nav .nav1', function () {
			$(this).next().toggleClass('active');
			return false;
		})
		$(document).on('click', '.nav__search a', function () {
			$(this).next().toggleClass('active');
			return false;
		})
	}
	if ($("#calendarpage").length) {
		axios
			.get('/assets/pdf/Calendar.js')
			.then((response) => {
				// console.log(response.data['磐城中央病院']);
				$('.update').each(function (index, el) {
					// console.log($(el).data('update'));
					$(el).html(response.data[$(el).data('update')]);
				})
			})
	}
})
